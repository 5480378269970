import React, { createContext, useEffect, useState, useContext } from "react";
import axios from "axios";

interface IClientState {
  uuid: string;
  phone: string;
  pin: string;
  userName: string;
  langNum: number;
  langCode: string;
  email: string;
  age: string;
  gender?: string;
  country: string;
  dateAppRegistered: Date;
  preDate: Date;
  postDate:  Date;
  CONFScorePre: number,
  CONFScorePost: number,
}
interface IClientContext {
  clientState: IClientState;
  updateClientState: (state: Partial<IClientState>, save?: boolean) => void;
  resetClientState: () => void;
}

const initialState: IClientState = {
  uuid: "",
  phone: "",
  pin: "0000",
  userName: "",
  langNum: 0,
  langCode: "en-GB",
  email: "",
  age: "",
  gender: "",
  country: "",
  dateAppRegistered: new Date(),
  preDate:new Date(),
  postDate: new Date(),
  CONFScorePre: 0,
  CONFScorePost: 0,
};

export const ClientContext = createContext<IClientContext>({
  clientState: initialState,
  updateClientState: () => {},
  resetClientState: () => {},
});

const getKey = (uuid: string) => "client_" + uuid;

const ClientState = (props: { children: React.ReactNode }) => {
  const [clientState, setClientState] = useState<IClientState>(() => {
    const uuidOnSS = sessionStorage.getItem("uuidOnSS");
    if (uuidOnSS) {
      const key = getKey(uuidOnSS);
      const storedState = localStorage.getItem(key);
      if (storedState) {
        console.log("Initializing state from localStorage");
        return JSON.parse(storedState);
      }
    }
    console.log("Initializing state with initial state");
    return initialState;
  });

  useEffect(() => {
    const uuidOnSS = sessionStorage.getItem("uuidOnSS");
    if (uuidOnSS) {
      const key = getKey(uuidOnSS);
      const storedState = localStorage.getItem(key);
      if (storedState) {
        console.log("Loaded state from localStorage on mount");
        setClientState(JSON.parse(storedState));
      }
    }
  }, []);

  useEffect(() => {
    if (clientState.uuid) {
      const key = getKey(clientState.uuid);
      console.log("Saving state to localStorage");
      localStorage.setItem(key, JSON.stringify(clientState));
    }
  }, [clientState]);

  const updateClientState = (state: Partial<IClientState>, save: boolean = true) => {
    setClientState((prev) => ({
      ...prev,
      ...state,
    }));
    if (save) {
      const key = getKey(state.uuid || clientState.uuid);
      localStorage.setItem(key, JSON.stringify({ ...clientState, ...state }));
    }
  };

  const resetClientState = () => {
    console.log("Resetting state to initial state");
    setClientState(initialState);
  };

  return (
    <ClientContext.Provider
      value={{
        clientState,
        updateClientState,
        resetClientState,
      }}
    >
      {props.children}
    </ClientContext.Provider>
  );
};

export const useClientContext = () => {
  const context = useContext(ClientContext);
  if (!context) {
    throw new Error("useClientContext must be used within a ClientProvider");
  }
  return context;
};

export default ClientState;
