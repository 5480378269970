import React, { lazy, Suspense } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import ClientState from "./contexts/ClientContext";

const Home = React.lazy(() => import("./screens/Home"));
const MainMenu = React.lazy(() => import("./screens/MainMenu"));
const About = React.lazy(() => import("./screens/About"));
const Contact = React.lazy(() => import("./screens/Contact"));
const AssessCONF = React.lazy(() => import("./screens/AssessCONF"));
const AssessResults = React.lazy(() => import("./screens/AssessResults"));

const CWA0 = React.lazy(() => import("./screens/partA/CWA0"));
const CWAPurpose = React.lazy(() => import("./screens/partA/CWAPurpose"));
const CWAIntro = React.lazy(() => import("./screens/partA/CWAIntro"));
const CWAPairs = React.lazy(() => import("./screens/partA/CWAPairs"));
const CWANoShort = React.lazy(() => import("./screens/partA/CWANoShort"));
const CWAIcebreakers = React.lazy(
  () => import("./screens/partA/CWAIcebreakers")
);
const Coaching = React.lazy(() => import("./screens/partA/Coaching"));
const CWAGradually = React.lazy(() => import("./screens/partA/CWAGradually"));
const Memory = React.lazy(() => import("./screens/partA/Memory"));
const CWACoachOne = React.lazy(() => import("./screens/partA/CWACoachOne"));
const CWAKnow = React.lazy(() => import("./screens/partA/CWAKnow"));
const CWAPieces = React.lazy(() => import("./screens/partA/CWAPieces"));
const CWAMedClosed5 = React.lazy(() => import("./screens/partA/CWAMedClosed5"));
const CWAMedClosed30 = React.lazy(
  () => import("./screens/partA/CWAMedClosed30")
);
const CWAMedOpen5 = React.lazy(() => import("./screens/partA/CWAMedOpen5"));
const CWAMedOpen30 = React.lazy(() => import("./screens/partA/CWAMedOpen30"));
const CWASpeak = React.lazy(() => import("./screens/partA/CWASpeak"));
const CWAAcks = React.lazy(() => import("./screens/partA/CWAAcks"));
const CWATypesConv = React.lazy(() => import("./screens/partA/CWATypesConv"));
const CWAAnswered = React.lazy(() => import("./screens/partA/CWAAnswered"));
const CWAImitation = React.lazy(() => import("./screens/partA/CWAImitation"));
const Rubbish = React.lazy(() => import("./screens/partA/Rubbish"));
const CWASillyFaces = React.lazy(() => import("./screens/partA/CWASillyFaces"));
const CWASillyNoises = React.lazy(
  () => import("./screens/partA/CWASillyNoises")
);
const CWALaughing = React.lazy(() => import("./screens/partA/CWAPairs"));
const CWAAnsweredDespite = React.lazy(
  () => import("./screens/partA/CWAAnsweredDespite")
);
const CWAAgain = React.lazy(() => import("./screens/partA/CWAAgain"));

const CWBCompletion = React.lazy(() => import("./screens/partB/CWBCompletion"));
const CWBEmbarrassment = React.lazy(
  () => import("./screens/partB/CWBEmbarrassment")
);
const CWBEmotions = React.lazy(() => import("./screens/partB/CWBEmotions"));
const CWBEndOfCourse = React.lazy(() => import("./screens/partB/CWBEndOfCourse"));
const CWBGiveBook = React.lazy(() => import("./screens/partB/CWBGiveBook"));
const CWBGoodAt = React.lazy(() => import("./screens/partB/CWBGoodAt"));
const CWBLearningObjective = React.lazy(
  () => import("./screens/partB/CWBLearningObjective")
);
const CWBLies = React.lazy(() => import("./screens/partB/CWBLies"));

const CWBLookWall = React.lazy(() => import("./screens/partB/CWBLookWall"));
const CWBLookWallSilently = React.lazy(
  () => import("./screens/partB/CWBLookWallSilently")
);
const CWBPerfectSpeech = React.lazy(
  () => import("./screens/partB/CWBPerfectSpeech")
);
const CWBStart = React.lazy(() => import("./screens/partB/CWBStart"));
const CWBSummarise = React.lazy(() => import("./screens/partB/CWBSummarise"));
const CWBWaffle = React.lazy(() => import("./screens/partB/CWBWaffle"));
const CWBWithoutHesitation = React.lazy(
  () => import("./screens/partB/CWBWithoutHesitation")
);

////////////////////////
const App: React.FC = () => {
  return (
    <div>
      <ClientState>
        <Router>
          <Suspense fallback={<div>Loading...</div>}>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/MainMenu" element={<MainMenu />} />
              <Route path="/About" element={<About />} />
              <Route path="/Contact" element={<Contact />} />
              <Route path="/AssessCONF/:id" element={<AssessCONF />} />
              <Route path="/AssessResults/:id" element={<AssessResults />} />

              
              <Route path="/CWA0" element={<CWA0 />} />
              <Route path="/CWAPurpose" element={<CWAPurpose />} />
              <Route path="/CWAIntro" element={<CWAIntro />} />
              <Route path="/CWAPairs" element={<CWAPairs />} />
              <Route path="/CWANoShort" element={<CWANoShort />} />
              <Route path="/CWAIcebreakers" element={<CWAIcebreakers />} />
              <Route path="/Coaching" element={<Coaching />} />
              <Route path="/CWAGradually" element={<CWAGradually />} />
              <Route path="/Memory" element={<Memory />} />
              <Route path="/CWACoachOne" element={<CWACoachOne />} />
              <Route path="/CWAKnow" element={<CWAKnow />} />
              <Route path="/CWAPieces" element={<CWAPieces />} />
              <Route path="/CWAMedClosed5" element={<CWAMedClosed5 />} />
              <Route path="/CWAMedClosed30" element={<CWAMedClosed30 />} />
              <Route path="/CWAMedOpen5" element={<CWAMedOpen5 />} />
              <Route path="/CWAMedOpen30" element={<CWAMedOpen30 />} />
              <Route path="/CWASpeak" element={<CWASpeak />} />
              <Route path="/CWAAcks" element={<CWAAcks />} />
              <Route path="/CWATypesConv" element={<CWATypesConv />} />
              <Route path="/CWAAnswered" element={<CWAAnswered />} />
              <Route path="/CWAImitation" element={<CWAImitation />} />
              <Route path="/Rubbish" element={<Rubbish />} />
              <Route path="/CWASillyFaces" element={<CWASillyFaces />} />
              <Route path="/CWASillyNoises" element={<CWASillyNoises />} />
              <Route path="/CWALaughing" element={<CWALaughing />} />
              <Route
                path="/CWAAnsweredDespite"
                element={<CWAAnsweredDespite />}
              />
              <Route path="/CWAAgain" element={<CWAAgain />} />
          

              <Route path="/CWBCompletion" element={<CWBCompletion />} />
              <Route path="/CWBEmbarrassment" element={<CWBEmbarrassment />} />
              <Route path="/CWBEmotions" element={<CWBEmotions />} />
              <Route path="/CWBEndOfCourse" element={<CWBEndOfCourse />} />
              <Route path="/CWBGiveBook" element={<CWBGiveBook />} />
              <Route path="/CWBGoodAt" element={<CWBGoodAt />} />
              <Route
                path="/CWBLearningObjective"
                element={<CWBLearningObjective />}
              />
              <Route path="/CWBLies" element={<CWBLies />} />
              <Route path="/CWBLookWall" element={<CWBLookWall />} />{" "}
              <Route
                path="/CWBLookWallSilently"
                element={<CWBLookWallSilently />}
              />
              <Route path="/CWBPerfectSpeech" element={<CWBPerfectSpeech />} />
              <Route path="/CWBStart" element={<CWBStart />} />
              <Route path="/CWBSummarise" element={<CWBSummarise />} />
              <Route path="/CWBWaffle" element={<CWBWaffle />} />
              <Route
                path="/CWBWithoutHesitation"
                element={<CWBWithoutHesitation />}
              />
              <Route
                path="/CWBLookWallSilently"
                element={<Navigate replace to="/CWBPerfectSpeech" />}
              />
              <Route
                path="/CWBSummarise"
                element={<Navigate replace to="/CWBPerfectSpeech" />}
              />
              <Route
                path="/CWBWaffle"
                element={<Navigate replace to="/CWBPerfectSpeech" />}
              />
              <Route
                path="/CWBWithoutHesitation"
                element={<Navigate replace to="/CWBPerfectSpeech" />}
              />
              <Route path="*" element={<Navigate replace to="/" />} />
            </Routes>
          </Suspense>
        </Router>
      </ClientState>
    </div>
  );
};

export default App;
